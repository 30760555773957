<template>
    <div>
        <form id="form-checkout" >
            <v-card >
                <v-card-text>
                    <Loading :active="loading" text="El hechizo se está completando"></Loading>
                    <v-container grid-list-xs>          
                        <v-row>
                            <v-col cols="12">
                                No de tarjeta
                                <v-text-field
                                    type="text" 
                                    name="cardNumber" 
                                    id="form-checkout__cardNumber"
                                    rounded
                                    filled
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                Mes de expiración
                                <v-text-field 
                                    type="text" 
                                    name="cardExpirationMonth" 
                                    id="form-checkout__cardExpirationMonth"
                                    rounded
                                    filled
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                Año de expiración
                                <v-text-field 
                                    type="text" 
                                    name="cardExpirationYear" 
                                    id="form-checkout__cardExpirationYear" 
                                    rounded
                                    filled
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                CVV
                                <v-text-field 
                                    type="text" 
                                    name="securityCode" 
                                    id="form-checkout__securityCode"
                                    rounded
                                    filled
                                />
                            </v-col>
                            <v-col cols="12">
                                Nombre como aparece en la tarjeta
                                <v-text-field 
                                    type="text" 
                                    name="cardholderName" 
                                    id="form-checkout__cardholderName"
                                    rounded
                                    filled
                                />    
                            </v-col>
                            <v-col cols="12">
                                Correo electrónico
                                <v-text-field 
                                    type="email" 
                                    name="cardholderEmail" 
                                    id="form-checkout__cardholderEmail"
                                    rounded
                                    filled
                                />    
                            </v-col>
                            <v-col cols="12">
                                Cuotas
                                <v-select
                                    :items="installments"
                                    rounded
                                    filled
                                    v-model="installment"
                                    item-value="installments"
                                    item-text="recommended_message"
                                ></v-select>    
                            </v-col>
                        </v-row>
                        
                        <select v-show="false" name="issuer" id="form-checkout__issuer" style="width:100%"></select>
                        <select name="identificationType" v-show="false" id="form-checkout__identificationType" style="width:100%"></select>

                        <v-text-field type="text" v-show="false" name="identificationNumber" id="form-checkout__identificationNumber"/>
                        <!-- Meses -->
                        <!-- Apellido(s)
                        <v-text-field
                            name="clientFormLastName"                            
                            id="clientFormLastName"
                            rounded
                            filled
                            v-model="form.customer.last_name"
                        ></v-text-field> -->
                        
                        
                        <select name="installments" v-show="false" v-model="installment" id="form-checkout__installments" style="width:100%"></select>
                        
                        <progress v-show="false" value="0" class="progress-bar">Cargando...</progress>                        
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" type="submit" rounded large>Pagar</v-btn>
                </v-card-actions>
            </v-card>
        </form>
    </div>
    
    
</template>
<script>
const mp = new MercadoPago(process.env.VUE_APP_MERCADOPAGO_PUBLIC_KEY,{
        locale: 'es-MX'
    });

import Loading from '@/components/Loading';
export default {
    components:{
        Loading
    },
    data:()=>({
        loading:false,
        installment:"1",
        installments:[],
    }),
    methods:{
        save(e){
            let res=this.cardForm.getCardFormData();
            console.log("aca andamos",res);
        }
    },
    mounted(){
        let self=this;
        const cardForm = mp.cardForm({
        amount: "100.5",
        autoMount: true,
        // processingMode: 'aggregator',
        form: {
            id: "form-checkout",
            cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Titular de la tarjeta",
            },
            cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
            },
            cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número de la tarjeta",
            },
            cardExpirationMonth: {
            id: "form-checkout__cardExpirationMonth",
            placeholder: "Mes de vencimiento",
            },
            cardExpirationYear: {
            id: "form-checkout__cardExpirationYear",
            placeholder: "Año de vencimiento",
            },
            securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "Código de seguridad",
            },
            installments: {
            id: "form-checkout__installments",
            placeholder: "Cuotas",
            },
            identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
            },
            identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Número de documento",
            },
            issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emisor",
            },
        },
        callbacks: {
            onFormMounted: error => {
            if (error) return console.warn("Form Mounted handling error: ", error);
            console.log("Form mounted");
            },
            onIdentificationTypesReceived: (error, identificationTypes) => {
                if (error) return console.warn('identificationTypes handling error: ', error)
                console.log('Identification types available: ', identificationTypes)
            },
            onPaymentMethodsReceived: (error, paymentMethods) => {
                if (error) return console.warn('paymentMethods handling error: ', error)
                console.log('Payment Methods available: ', paymentMethods)
            },
            onIssuersReceived: (error, issuers) => {
                if (error) return console.warn('issuers handling error: ', error)
                console.log('Issuers available: ', issuers)
            },
            onInstallmentsReceived: (error, installments) => {
                if (error) return console.warn('installments handling error: ', error);
                self.installments=installments.payer_costs;
            },
            onCardTokenReceived: (error, token) => {
                if (error) self.$emit("token-error",error);
            },
            onSubmit: event => {
            event.preventDefault();

            self.$emit("created-card",cardForm.getCardFormData());
            },
            onFetching: (resource) => {
                console.log("Fetching resource: ", resource);
                self.loading=true;
                return () => {
                    setTimeout(() => {
                        self.loading=false;
                    }, 2000);
                    
                };
            }
        },
        });
        this.cardForm=cardForm;
    }
}
</script>